import { createWebHistory, createRouter } from "vue-router";
import guest from './middleware/guest'
import auth from './middleware/auth'
import store from './store'
import middlewarePipeline from './middleware/middlewarePipeline'

const routes =  [
  {
    path: "/",
    name: "Home",
    component: () => import("./components/Appointment/List"),
    meta: {
      middleware: [
          auth
      ]
    }
  },
  {
    path: "/afspraken/afgerond",
    name: "Afgeronde afspraken",
    component: () => import("./components/Appointment/ListFinished"),
    meta: {
      middleware: [
          auth
      ]
    }
  },
  {
    path: "/afspraken",
    name: "Afspraken",
    component: () => import("./components/Appointment/List"),
    meta: {
      middleware: [
          auth
      ]
    }
  },
  {
    path: "/excel/upload",
    name: "Excel",
    component: () => import("./components/Excel/Upload"),
    meta: {
      middleware: [
          auth
      ]
    }
  },
  {
    path: "/afspraken",
    name: "Afspraken",
    component: () => import("./components/Appointment/List"),
    meta: {
      middleware: [
          auth
      ]
    }
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./components/Auth/Login"),
    meta: {
      middleware: [
          guest
      ]
    }
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("./components/Auth/Register"),
    meta: {
      middleware: [
          auth
      ]
    }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("./components/Auth/Dashboard"),
    meta: {
      middleware: [
          auth
      ]
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
      return next()
  }
  const middleware = to.meta.middleware

  const context = {
      to,
      from,
      next,
      store
  }

  // This makes it possible to have multiple rules on url's (as of guest, or is admin if we add it in our middleware)
  return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1)
  })

})


export default router;