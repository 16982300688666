<template>
	<nav class="main background-dark-blue">
		<div class="header-logo">
			<router-link to="/"><img alt="Powered by coolfox" :src="require('../assets/coolfox logo-wit.png')"
					class="nav-logo-img" /></router-link>
					<b style="color: red"> STORING SERVICE </b>
		</div>
		<div class="nav-content" v-on:click="closeMenu()">
			<router-link to="/afspraken">Afspraken</router-link>
			<router-link to="/excel/upload"> Excel Importeren</router-link>
			<a> | </a>
			<template v-if="user.loggedIn">
				<a>Welcome {{user.data.displayName}}</a>
				<a @click.prevent="signOut">Sign out</a>
			</template>
			<template v-else>
				<router-link to="login">Login</router-link>
				<router-link to="register">Register</router-link>
			</template>
			<!-- <a @click="logout" style="cursor: pointer;">Uitloggen</a> -->
		</div>
		<!-- <div class="mobile-menu" v-on:click="toggleMenu()">
			<b-icon-menu-button></b-icon-menu-button>
		</div> -->
	</nav>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import firebase from "../firebase";
	export default {
		computed: {
			...mapGetters({
				// map `this.user` to `this.$store.getters.user`
				user: "user"
			})
		},
		components: {},
		methods: {
			toggleMenu() {
				const el = document.getElementsByClassName('nav-content');
				if (el[0].classList.contains('open')) {
					el[0].classList.remove('open');
				} else {
					el[0].classList.add('open');
				}
			},
			closeMenu() {
				const el = document.getElementsByClassName('nav-content');
				el[0].classList.remove('open');
			},
			signOut() {
				firebase
					.auth()
					.signOut()
					.then(() => {
						this.$router.replace({
							name: "Home"
						});
					});
			}
		}
	}
</script>