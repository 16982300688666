import firebase from "firebase/app";
require('firebase/auth')
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAuNnqqMrXnCpyekIT7fJCdh8p5B4t9MEs",
  authDomain: "storingservice-cce86.firebaseapp.com",
  databaseURL: "https://storingservice-cce86-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "storingservice-cce86",
  storageBucket: "storingservice-cce86.appspot.com",
  messagingSenderId: "222935885469",
  appId: "1:222935885469:web:535f854983cc7e4f225674",
  measurementId: "G-ZFF3DVPE82"
};

var firebaseApp = firebase.initializeApp(firebaseConfig);

// Default only exported the database. But when auth needed, it could only fetch the database.
// Fixed by just changing the services and returning the whole firebase object. (could be split into multiple exports, but eh?)
export default firebaseApp;
