<template>
<div class="footer-wrapper">

</div>

</template>

<script>
export default {
    
}
</script>

<style scoped>

</style>