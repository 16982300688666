import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import firebase from "./firebase"
import store from "./store"
import router from './router'


// PrimeVue components that I have to import for every little component you might use like once or twice and then never again but hey you still need to import it for whatever reason. 
import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
// import RadioButton from 'primevue/radiobutton';
// import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar'
import Textarea from 'primevue/textarea';

//Global CSS file
import "@/assets/css/main.css";
import "primevue/resources/primevue.min.css"                 
import "primeicons/primeicons.css"
import "primevue/resources/themes/arya-green/theme.css"         


firebase.auth().onAuthStateChanged(user => {
    store.dispatch("fetchUser", user);
  });


const app = createApp(App);
app.use(router);
app.use(PrimeVue);
app.use(store);
app.mount('#app')


// PrimeVue components that you have to define into your application again that you have to~ you know the drill
app.component('DataTable', DataTable );
app.component('Column', Column);
app.component('Dialog', Dialog);
app.component('Button', Button);
app.component('InputText', InputText);
app.component('InputNumber', InputNumber);
app.component('Textarea', Textarea)
app.component('Calendar', Calendar);