<template>
  <div>
    <main-navbar></main-navbar>
    <main-header></main-header>
    <main-footer></main-footer>
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
export default {
  name: "app",
  components: {
    "main-header":Header,
    "main-navbar": Navbar,
    "main-footer":Footer
  }
};
</script>

<style scoped>
.container h2 {
  text-align: center;
  margin: 25px auto;
}
</style>