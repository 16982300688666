function middlewarePipeline (context, middleware, index) {
    //Context is an object so it can be passed each peace of the middlware in the stack.
    //Middleware is the array itself as defined on the meta field
    //Index is the current middleware being run in the middleware array.

    const nextMiddleware = middleware[index]

    if(!nextMiddleware){
        //No middleware found, we pass it to next.
        return context.next 
    }

    return () => {
        //MiddlewarePipeline is a recursive function that will fetch every next middleware in the array.
        const nextPipeline = middlewarePipeline(
            context, middleware, index + 1
        )

        nextMiddleware({ ...context, next: nextPipeline })

    }
}

export default middlewarePipeline